import React from "react";
import {
	MDBCard,
	MDBCardTitle,
	MDBCardText,
	MDBCardBody,
	MDBCardImage,
	MDBRow,
	MDBCol,
	MDBView,
	MDBContainer
} from "mdbreact";
import { GGgetMode } from "../../services/gglocalstorage";

/**
 * Card do coordenador de curso.
 */
export default function CardCoordenador({ coordenadorSelecionado }) {

	const nomeCoordenador =
		coordenadorSelecionado?.Informacoes?.NomeCoordenador?.Value;
    const informacoesContato =
		coordenadorSelecionado?.Informacoes?.InformacoesContato?.Value;
    const temImagem =
		coordenadorSelecionado?.Informacoes?.ImagemCoordenador?.HasValue;
    const urlImagem =
		coordenadorSelecionado?.Informacoes?.ImagemCoordenador?.Media?.PublicUrl;

	// Modificação para alterar o Ramal de Medicina Veterinária
	let medicinavet = nomeCoordenador === "Prof. Dr. Gabriel Ribas Pereira" ? "<p>E-mail: medicinaveterinaria@feevale.br<br>Telefone: (51) 3586 8800, ramal 6471</p>" : informacoesContato;

	return (
		<MDBRow className="py-3 text-center text-md-left">
			{temImagem && !GGgetMode() && (
				<MDBCol md='3'>
					<img className="img-fluid rounded-circle z-depth-1-half" style={{ objectFit: "cover", width: 150, height: 150 }} src={urlImagem} />
				</MDBCol>
			)}
			<MDBCol className="pt-3 pt-md-0 mt-auto mb-auto">
				<div className={GGgetMode() && "text-white"}>
					<h4 className="text-secondary">Coordenação do curso</h4>
					<h6 className="font-weight-bold">{nomeCoordenador}</h6>
					<div dangerouslySetInnerHTML={{ __html: medicinavet }} />
				</div>
			</MDBCol>
		</MDBRow>
	);
}